import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RouteguardService } from './routeguard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login-partner', pathMatch: 'full' },
  //  {
  //   path: 'car',
  //   loadChildren: './car-module/car-module.module#CarModuleModule'
  // },
  { path: '', loadChildren: () => import('./common-module/common-module.module').then(m => m.CommonModuleModule) },

  { path: 'parking', loadChildren: () => import('./parking-module/parking-module.module').then(m => m.ParkingModuleModule) },

  { path: 'travel', loadChildren: () => import('./car-module/car-module.module').then(m => m.CarModuleModule) },
  // {path: 'members', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)},
  // {path: 'login', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  // {path: 'members', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)},
  // {path: 'add-newparkings', component: AddNewparkingsComponent, canActivate: [Routeguard/Service]},
  // {path: 'dashboard', component: DashBoardComponent},
  // {path: 'edit-parkings', component: EditparkingsComponent, canActivate: [RouteguardService]},

  // { path: '**', redirectTo: '/404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
