import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
// import { Headers, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  configs: any;
  public URL: any
  public URLAPI: any
  public CarServiceType = 'Airport Transfer';
  public ParkingServiceType = 'Airport Parking';
  public ParkingPortServiceType = 'Airport/Port Parking';
  public PassedRolesName = [
    { item_id: 1, item_text: 'Booking-Details', name: 'completed_notShown_Btn_clk', isChecked: false },
    { item_id: 2, item_text: 'Finance', name: 'finance', isChecked: false },
    { item_id: 3, item_text: 'Parking-Details', name: 'ps_Add_or_edit', isChecked: false },
  ]
  constructor(handler: HttpBackend, private http: HttpClient) {
    this.http = new HttpClient(handler)
    // var getCookeisDataUserlogin = this.cookieService.get('partnerLogindetails');
    // var getCookeisDataUserlogin = localStorage.getItem('partnerLogindetails')
    // if (getCookeisDataUserlogin) {
    //   this.partnerDetailsCookiesData = getCookeisDataUserlogin
    // }

    var getExpriyDatePartner = localStorage.getItem('getPartnerExpriyDate');

    var getExpriyDatePartnerParsedData = JSON.parse(getExpriyDatePartner)

    if (getExpriyDatePartnerParsedData && localStorage.getItem("partnerLogindetails")) {

      var GetStorageDate = new Date(getExpriyDatePartnerParsedData);

      GetStorageDate.setDate(GetStorageDate.getDate() + 3)

      if (GetStorageDate > new Date()) {
        var encryptInfoPartner = localStorage.getItem("partnerLogindetails")
        var decryptPartnerData = CryptoJS.AES.decrypt(decodeURIComponent(encryptInfoPartner), 'Iniyas@123');
        var decryptedInfoPartner = JSON.parse(decryptPartnerData.toString(CryptoJS.enc.Utf8));
        this.partnerDetailsCookiesData = JSON.stringify(decryptedInfoPartner);
      }
      else {
        localStorage.removeItem("partnerLogindetails")
        localStorage.removeItem("getPartnerExpriyDate")
      }
    }
    // this.activateRoute.paramMap.subscribe(params => {
    //      var getEmailverifyid= params.get('admin');
    //      // this.getcodeverifyid=params.get('code');
    // });
    var ShowedAdminaccessData;
    if (sessionStorage.getItem("adminAccessedPartner")) {
      var GetadminAccessedValues = sessionStorage.getItem("adminAccessedPartner")
      ShowedAdminaccessData = JSON.parse(GetadminAccessedValues);
    }
    if (this.partnerDetailsCookiesData) {
      // var getlogindetails = this.partnerDetailsCookiesData
      var showedlogindetails = JSON.parse(this.partnerDetailsCookiesData);
      this.loindetialspartner = showedlogindetails.response;
      // if(this.loindetialspartner.result && this.loindetialspartner.result[0].service_type==="Airport Transfer"){
      //   this.router.navigate(['/travel/dashboard']);
      // }
    }

    else if (ShowedAdminaccessData) {
      // var getlogindetails=sessionStorage.getItem("adminAccessedPartner")
      this.loindetialspartner = ShowedAdminaccessData.response;

    }
  }
  get ApiUrl() {
    return this.configs.ApiEndpoint
  }
  get configLoadedFrom() {
    return this.configs.configLoadedFrom
  }
  public async loadConfig(): Promise<any> {
    return this.http
      .get('assets/config.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.configs = settings as configs;
        this.URLAPI = this.configs.ApiEndpoint + "api";
        this.URL = this.configs.ApiEndpoint + "asset/";
      });
  }
  getAdmindataLoaded() {
    if (this.partnerDetailsCookiesData) {
      // var getlogindetails = this.partnerDetailsCookiesData
      var showedlogindetails = JSON.parse(this.partnerDetailsCookiesData);
      this.loindetialspartner = showedlogindetails.response;
    }
    else if (sessionStorage.getItem("adminAccessedPartner")) {
      var getlogindetailsadmin = sessionStorage.getItem("adminAccessedPartner")
      var showedlogindetails = JSON.parse(getlogindetailsadmin);
      this.loindetialspartner = showedlogindetails.response;
    }
  }


  // public URL = "https://trinqobackendtest.azurewebsites.net"
  // public URLAPI = "https://trinqobackendtest.azurewebsites.net/api"

  // public URL = "http://iniyasdev.ukwest.cloudapp.azure.com:3000"
  // public URLAPI = "http://iniyasdev.ukwest.cloudapp.azure.com:3000/api"
  public subscribepartnerget;
  public loindetialspartner;
  public forHeaderImageload;

  public partnerDetailsCookiesData;
  public CookiesExpiryDate = 3

  getparkingdetails(partnerID) {
    return this.http.get(this.URLAPI + "/partner/trinqo/partner/parking_space/" + partnerID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  addPartnerurl(partnerID, datasss) {
    return this.http.post(this.URLAPI + "/partner/addParkingSpace/" + partnerID, datasss, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  editformmanage(editdata, partnerID, IDmanage) {
    return this.http.put(this.URLAPI + "/partner/updateParkingSpace/" + IDmanage + "/" + partnerID, editdata, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  loginpartner(loginget) {
    return this.http.post(this.URLAPI + "/partner/unameValidate", loginget);
  }
  subscrbepartner() {
    return this.http.get(this.URLAPI + "/partner/uname/" + this.subscribepartnerget);
  }
  feedbackpartner(getid) {
    return this.http.get(this.URLAPI + "/partner/booking/bookingdetailsfeedback/" + getid + "/" + this.ParkingServiceType);
  }
  partnersingup(getvaluesingup) {
    return this.http.post(this.URLAPI + "/partner/add/", getvaluesingup);
  }

  // get partner details
  getpartnerlogindetails(GetPArtnerID) {
    return this.http.get(this.URLAPI + "/partner/get/" + GetPArtnerID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }

  // file upload
  public uploaduserIDprofile
  public profilepiId
  postproilepic(fileToUploadpic: File) {
    //const endpoint = this.URLAPI+'/user/profilePicUpload';
    const formData: FormData = new FormData();
    var selectedFilespic = fileToUploadpic;
    formData.append('userId', this.uploaduserIDprofile);
    if (selectedFilespic != null) {
      formData.append('file', selectedFilespic);
      return this.http.post(this.URLAPI + "/user/profilePicUpload/", formData);

    }
  }
  // partnersingup(getvaluesingup,fileToUploadpic: File){
  // const formData: FormData = new FormData();
  //   var selectedFilespic=fileToUploadpic;
  //    formData.append('username', getvaluesingup.username);
  //    if(selectedFilespic!=null){
  //     formData.append('file', selectedFilespic);
  //     return this.http.post(this.URLAPI+"/partner/add", getvaluesingup);
  //   }
  // }
  getParkingspacedetail(passedparkingID) {
    return this.http.get(this.URLAPI + "/Common/getoneParkingSpace/" + passedparkingID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  myaccountProfileupdate(myaccID, formdatasUPdate) {
    return this.http.put(this.URLAPI + "/partner/update/" + myaccID, formdatasUPdate, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getdashboardbooking(partnerIDdash) {
    return this.http.get(this.URLAPI + "/partner/booking/getBookingdetailsbookedcountbypartnerid/" + partnerIDdash + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getdashboardprofit(partnerIDdash) {
    return this.http.get(this.URLAPI + "/partner/booking/getBookingdetailsprofitbypartnerid/" + partnerIDdash + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  partnerChangePassword(partnerIDpass, email, dataPAssword) {
    return this.http.post(this.URLAPI + "/partner/passwordchange/" + partnerIDpass + "/" + email, dataPAssword, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  partnerGetallbooking(partnerIDpasget) {
    return this.http.get(this.URLAPI + "/partner/trinqo/allparkingspacebookings/" + partnerIDpasget + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getpartnerapproveStatus(partnerIDapprove) {
    return this.http.get(this.URLAPI + "/partner/partneracceptrejinfo/" + partnerIDapprove + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getpartnerapproveStatuschecked(partnerIDapprove) {
    return this.http.get(this.URLAPI + "/partner/partneracceptrejinfoChecked/" + partnerIDapprove, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getparkingapproveStatus(parkingIDapprove) {
    return this.http.get(this.URLAPI + "/partner/parkingacceptrejinfo/" + parkingIDapprove + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getparkingapproveStatuschecked(parkingIDapprove, partnerID) {
    return this.http.get(this.URLAPI + "/partner/parkingacceptrejinfoChecked/" + parkingIDapprove + "/" + partnerID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getairportList(passAirport) {
    return this.http.post(this.URLAPI + "/Common/airportlist/details", passAirport);

  }
  bookingIDgetdetails(IDBooking) {
    return this.http.get(this.URLAPI + "/Common/bookingdetail/" + IDBooking + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  completedButtonclick(editdata, partnerID, test) {
    return this.http.put(this.URLAPI + "/partner/completedbooking/" + editdata + "/" + partnerID + "/" + this.ParkingServiceType, test, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  notshowButtonclick(editdata, partnerID, test) {
    return this.http.put(this.URLAPI + "/partner/notshownbooking/" + editdata + "/" + partnerID + "/" + this.ParkingServiceType, test, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  BankDetailsFor(BankDetails, ID) {
    return this.http.post(this.URLAPI + "/partner/acct_info/" + ID, BankDetails, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  BankUpdateFor(BankEditDetails, ID) {
    return this.http.put(this.URLAPI + "/partner/editacct_info/" + ID, BankEditDetails, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }

  BankshowFor(partnerID) {
    return this.http.get(this.URLAPI + "/partner/showacct_info/" + partnerID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  BandTableletter(parkingID, IDband, passdataBand) {
    return this.http.post(this.URLAPI + "/partner/insertband/" + IDband + "/" + parkingID, passdataBand, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }

  priceTableletter(parkingID, IDprice, passdataprice) {
    return this.http.post(this.URLAPI + "/partner/insertprice/" + IDprice + "/" + parkingID, passdataprice, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  priceTableletterupdate(parkingID, IDpriceupdate, passdatapriceupdate) {
    return this.http.put(this.URLAPI + "/Common/editprice/" + IDpriceupdate + "/" + parkingID, passdatapriceupdate, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getPricingeditupdate(parkingID, IDprice, passdataprice) {
    return this.http.post(this.URLAPI + "/partner/pricetable/" + IDprice + "/" + parkingID, passdataprice, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getbandshowpriceedit(parkignID, IDBand, passdataBand) {
    return this.http.post(this.URLAPI + "/partner/showbandval/" + IDBand + "/" + parkignID, passdataBand, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getPricingshowpriceedit(parkingID, IDprice, passdataprice) {
    return this.http.post(this.URLAPI + "/partner/showpriceval/" + IDprice + "/" + parkingID, passdataprice, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  bandtableUpdate(parkingID, IDbandupdate, passdatabandupdate) {
    return this.http.put(this.URLAPI + "/Common/editband/" + IDbandupdate + "/" + parkingID, passdatabandupdate, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  deletebandtable(ParkingID, passid, removevalue) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      }),
      body: removevalue
    }
    return this.http.delete(this.URLAPI + "/Common/deleteband/" + passid + "/" + ParkingID, options)
  }
  deletepricetable(ParkingID, passidprice, removevalueprice) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      }),
      body: removevalueprice
    }
    return this.http.delete(this.URLAPI + "/Common/deleteprice/" + passidprice + "/" + ParkingID, options)
  }

  revenuepagedata(partnerID, passedData) {
    return this.http.post(this.URLAPI + "/partner/Revenuechart/" + partnerID + "/" + this.ParkingServiceType, passedData, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }

  ratinggetpercentage(partnerID) {
    return this.http.get(this.URLAPI + "/partner/booking/Ratingfeedback/" + partnerID + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  otpmailget(passotpmail) {
    return this.http.post(this.URLAPI + "/partner/otppartner?email=" + passotpmail, "");
  }
  otpvalidationnumber(passotpnumber, otpvalue) {
    return this.http.post(this.URLAPI + "/partner/partnerotpvalidation?email=" + passotpnumber, otpvalue);
  }
  sentotpchangepassword(passmailid, password) {
    return this.http.post(this.URLAPI + "/partner/forgotpswdpartner?email=" + passmailid, password);
  }
  deleteParkingspace(getParkingID, partnerID) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      }),
    }
    return this.http.delete(this.URLAPI + "/partner/parkingspacedelete/" + getParkingID + "/" + partnerID, options)
  }
  getPartnerCompletedCount(partnerIDdash) {
    return this.http.get(this.URLAPI + "/partner/booking/getBookingdetailscountbypartnerid/" + partnerIDdash + "/" + this.ParkingServiceType + "?Booking_status=completed", {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getCountforCancel(partnerIDdash) {
    return this.http.get(this.URLAPI + "/partner/booking/getBookingdetailscountbypartnerid/" + partnerIDdash + "/" + this.ParkingServiceType + "?Booking_status=cancelled", {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  partnerBookingnotify(PartnerID) {
    return this.http.get(this.URLAPI + "/partner/bookingdetailofpartner/" + PartnerID + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getBookingchecked(bookingID, partnerID) {
    return this.http.get(this.URLAPI + "/partner/bookingdetailofpartnerChecked/" + bookingID + "/" + partnerID + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  paymentNOtificationpartner(partnerID) {
    return this.http.get(this.URLAPI + "/partner/getpaidstatuspartnerChecked/" + partnerID + "/" + this.ParkingServiceType, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  paymentNOtificationchecked(partner_id, refID) {
    return this.http.put(this.URLAPI + "/partner/paidstatuspartnerChecked/" + partner_id + "/" + this.ParkingServiceType, refID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  partnerverifyLink(passData) {
    return this.http.post(this.URLAPI + "/partner/verificationcheck", passData);
  }
  getCalenderINoutData(partner_id, datacalender) {
    return this.http.post(this.URLAPI + "/partner/bookingdetailsorcountformonth/" + partner_id, datacalender, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getPartnerDocument(documentServiceData) {
    return this.http.post(this.URLAPI + "/Admin/getdocumentName", documentServiceData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }
  partnerGetallbookingstatus(partnerIDpasget, getstatus) {
    return this.http.get(this.URLAPI + "/partner/trinqo/allparkingspacebookings/" + partnerIDpasget + "/" + this.ParkingServiceType + "?Booking_status=" + getstatus, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  // user roles
  GetallRolesuser(partnerID) {
    return this.http.get(this.URLAPI + "/partner/getAllMultiUsers/" + partnerID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  rolesAddedSuperpartner(partnerID, PassedData) {
    return this.http.post(this.URLAPI + "/partner/addMultiUser/" + partnerID, PassedData, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  getSingleRolesuser(roleID) {
    return this.http.get(this.URLAPI + "/partner/getMultiUsers/" + roleID, {
      headers: new HttpHeaders({
        'Authorization': this.loindetialspartner.token,
        'Content-Type': 'application/json',
      })
    });
  }
  editMultiUser(RolesID, passedId) {
    return this.http.put(this.URLAPI + "/partner/editMultiUser/" + RolesID, passedId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  deleteMultiUserRoles(passid) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      }),
      // body: removevalue
    }
    return this.http.delete(this.URLAPI + "/partner/deleteMultiUser/" + passid, options)
  }
  // end user roles
  getTermsandConditionparking(serviceType) {
    return this.http.get(this.URLAPI + "/Admin/gettermsandcondition/partner/" + serviceType)
  }
  getAllRoles(PartnerID) {
    return this.http.get(this.URLAPI + "/Admin/getrole/partner/" + PartnerID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  getPricingTableDefalut(PartnerID: any) {
    return this.http.get(this.URLAPI + "/partner/getdefaultband/" + PartnerID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      })
    });
  }
  updatePricingTableDefalut(PartnerID: any, bandName: any) {
    return this.http.put(this.URLAPI + "/partner/updateorinsertdefaultband/" + PartnerID + "?defaultband=" + bandName, "", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.loindetialspartner.token,
      })
    });
  }

//start code
// addcarprice(partnerID, PassedData) {
//   return this.http.post(this.URLAPI +"/partner/cab/cabAddpricetable/" + partnerID, PassedData, {
//     headers: new HttpHeaders({
//       'Authorization': this.loindetialspartner.token,
//       'Content-Type': 'application/json',
//     })
//   });
// }
//end code


}







export interface configs {
  ApiEndPoint: string;
  ConfigLoadedFrom: string
}
