import { Component } from '@angular/core';
import { ServiceService } from './service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(ServiceService: ServiceService) {

  }

  title = 'Trinqo Partner Site';
  ngOnInit() {

  }
}
